import { Card, Form, Container as BSContainer, Row, Col } from "react-bootstrap";
import { ChildContent } from "./ChildContent";
import {useState} from "react"

export const ParentDDL = (props) => {
  
  const [isChanged, setIsChanged] = useState(false)

  const blockTypeChanged = (event) => {
    setIsChanged(true)
    props.onSelectionOption(event)
  }
  
  return (
    <Card className="my-1" id={props.id} key={props.id}>
      <BSContainer>
        <Row className="px-0 my-1">
          <Col sm="10">
            <Form.Select value={props.selected} onChange={blockTypeChanged}>
              <option value="-1">Select Type:</option>
              <option value="1">Table</option>
              <option value="2">Informational</option>
              <option value="3">Buttons</option>
              <option value="4">Freeform</option>
              <option value="5">Search Field</option>
              <option value="6">Spacer</option>
            </Form.Select>
          </Col>
          <Col sm="auto" className="px-0">
            <button type="button" onClick={props.onRemoveItem} className="btn btn-secondary">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                <path
                  fillRule="evenodd"
                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                />
              </svg>
            </button>
          </Col>
        </Row>
        <ChildContent
          key={props.blockKeyId}
          blockId={props.blockId}
          blockKeyId={props.blockKeyId}
          content={props.content}
          selected={props.selected}
          getAdminSettings={props.getAdminSettings}
          mappingInput={props.mappingInput}
          setMappingInput={props.setMappingInput}
          removeMappingInput={props.removeMappingInput}
          initialValuesFromAPI={props.initialValuesFromAPI}
          isProd={props.isProd}
          isChanged={isChanged}
          setIsChanged={setIsChanged}
        />
      </BSContainer>
    </Card>
  );
};
