import React, { useState, useEffect, useRef } from "react";
import { Form, Row, Col } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";

export const StyleAccordion = (props) => {
  const [initialLoad, setInitialLoad] = useState(true);
  const initialLoadRef = useRef(true);
  initialLoadRef.current = initialLoad

  const [activeKeyAccordian, setActiveKeyAccordian] = useState(0);

  const [fontColorSelectedOptionTitle, setFontColorSelectedOptionTitle] = useState("#000000");
  const [fontSizeSelectedOptionTitle, setFontSizeSelectedOptionTitle] = useState("medium");
  const [fontFamilySelectedOptionTitle, setFontFamilySelectedOptionTitle] = useState("Segoe UI");
  const [colorBGSelectedOptionTitle, setColorBGSelectedOptionTitle] = useState("#F7F7F7");
  const [bgColorSF, setBGColorSF] = useState("#FFFFFF");

  const setFontSizeSelectedOptionSelectedTitle = (selected) => {
    setFontSizeSelectedOptionTitle(selected.target.value);
  };

  const setFontColorSelectedOptionSelectedTitle = (selected) => {
    setFontColorSelectedOptionTitle(selected.target.value);
  };

  const setFontFamilySelectedOptionSelectedTitle = (selected) => {
    setFontFamilySelectedOptionTitle(selected.target.value);
  };

  const setColorBGSelectedOptionTitleOnClick = (selected) => {
    setColorBGSelectedOptionTitle(selected.target.value);
  };

  const setBGColorSFOnClick = (selected) => {
    setBGColorSF(selected.target.value);
  };

  const [fontColorSelectedOptionHeader, setFontColorSelectedOptionHeader] = useState("#000000");
  const [fontSizeSelectedOptionHeader, setFontSizeSelectedOptionHeader] = useState("medium");
  const [fontFamilySelectedOptionHeader, setFontFamilySelectedOptionHeader] = useState("Segoe UI");

  const setFontSizeSelectedOptionSelectedHeader = (selected) => {
    setFontSizeSelectedOptionHeader(selected.target.value);
  };

  const setFontColorSelectedOptionSelectedHeader = (selected) => {
    setFontColorSelectedOptionHeader(selected.target.value);
  };

  const setFontFamilySelectedOptionSelectedHeader = (selected) => {
    setFontFamilySelectedOptionHeader(selected.target.value);
  };

  const [fontColorSelectedOptionBody, setFontColorSelectedOptionBody] = useState("#000000");
  const [fontSizeSelectedOptionBody, setFontSizeSelectedOptionBody] = useState("medium");
  const [fontFamilySelectedOptionBody, setFontFamilySelectedOptionBody] = useState("Segoe UI");

  const [alignmentBody, setAlignmentBody] = useState("center");
  const [italicBody, setItalicBody] = useState(false);
  const [boldBody, setBoldBody] = useState(false);
  const [underlineBody, setUnderlineBody] = useState(false);

  const [alignmentTitle, setAlignmentTitle] = useState("center");
  const [italicTitle, setItalicTitle] = useState(false);
  const [boldTitle, setBoldTitle] = useState(false);
  const [underlineTitle, setUnderlineTitle] = useState(false);

  const [alignmentHeader, setAlignmentHeader] = useState(props.selected === "1" ? "left" : "center");
  const [italicHeader, setItalicHeader] = useState(false);
  const [boldHeader, setBoldHeader] = useState(false);
  const [underlineHeader, setUnderlineHeader] = useState(false);

  const onChangeUnderlineBody = () => {
    setUnderlineBody(!underlineBody);
  };

  const onChangeBoldBody = () => {
    setBoldBody(!boldBody);
  };

  const onChangeItalicBody = () => {
    setItalicBody(!italicBody);
  };

  const onChangeAlignmentBody = (selected) => {
    setAlignmentBody(selected.target.value);
  };

  const onChangeUnderlineTitle = () => {
    setUnderlineTitle(!underlineTitle);
  };

  const onChangeBoldTitle = () => {
    setBoldTitle(!boldTitle);
  };

  const onChangeItalicTitle = () => {
    setItalicTitle(!italicTitle);
  };

  const onChangeAlignmentTitle = (selected) => {
    setAlignmentTitle(selected.target.value);
  };

  const onChangeUnderlineHeader = () => {
    setUnderlineHeader(!underlineHeader);
  };

  const onChangeBoldHeader = () => {
    setBoldHeader(!boldHeader);
  };

  const onChangeItalicHeader = () => {
    setItalicHeader(!italicHeader);
  };

  const onChangeAlignmentHeader = (selected) => {
    setAlignmentHeader(selected.target.value);
  };

  const setFontSizeSelectedOptionSelectedBody = (selected) => {
    setFontSizeSelectedOptionBody(selected.target.value);
  };

  const setFontColorSelectedOptionSelectedBody = (selected) => {
    setFontColorSelectedOptionBody(selected.target.value);
  };

  const setFontFamilySelectedOptionSelectedBody = (selected) => {
    setFontFamilySelectedOptionBody(selected.target.value);
  };

  const setActiveKeyAccordianOnClick = () => {
    const activeKey = activeKeyAccordian === 1 ? 0 : 1;
    setActiveKeyAccordian(activeKey);
  };

  const [skipValues, setSkipValues] = useState(false);
  const skipValuesRef = useRef(false);
  skipValuesRef.current = skipValues;

  useEffect(() => {
    if (props.isChanged) {
      skipValuesRef.current = true;
      setSkipValues(true);
      props.setIsChanged(false);
      initialLoadRef.current = true
      setInitialLoad(true)
    }
  }, [props.isChanged]);

  useEffect(() => {
    if (initialLoadRef.current) {
      if (props.initialValuesFromAPI !== undefined) {
        let valuesForBlock = props.initialValuesFromAPI.find((iv) => iv.id === String(props.blockId));
        if (valuesForBlock !== undefined) {
          if (!skipValuesRef.current && valuesForBlock.blockStyles != undefined) {
            valuesForBlock.blockStyles.forEach((bs) => {
              if (bs["fontColorInputTitle"] !== undefined) {
                setFontColorSelectedOptionTitle(bs["fontColorInputTitle"]);
              } else if (bs["fontSizeInputTitle"] !== undefined) {
                setFontSizeSelectedOptionTitle(bs["fontSizeInputTitle"]);
              } else if (bs["fontFamilyInputTitle"] !== undefined) {
                setFontFamilySelectedOptionTitle(bs["fontFamilyInputTitle"]);
              } else if (bs["fontColorInputHeader"] !== undefined) {
                setFontColorSelectedOptionHeader(bs["fontColorInputHeader"]);
              } else if (bs["fontSizeInputHeader"] !== undefined) {
                setFontSizeSelectedOptionHeader(bs["fontSizeInputHeader"]);
              } else if (bs["fontFamilyInputHeader"] !== undefined) {
                setFontFamilySelectedOptionHeader(bs["fontFamilyInputHeader"]);
              } else if (bs["fontColorInputBody"] !== undefined) {
                setFontColorSelectedOptionBody(bs["fontColorInputBody"]);
              } else if (bs["fontSizeInputBody"] !== undefined) {
                setFontSizeSelectedOptionBody(bs["fontSizeInputBody"]);
              } else if (bs["fontFamilyInputBody"] !== undefined) {
                setFontFamilySelectedOptionBody(bs["fontFamilyInputBody"]);
              } else if (bs["alignmentBody"] !== undefined) {
                setAlignmentBody(bs["alignmentBody"]);
              } else if (bs["italicBody"] !== undefined) {
                setItalicBody(bs["italicBody"]);
              } else if (bs["boldBody"] !== undefined) {
                setBoldBody(bs["boldBody"]);
              } else if (bs["underlineBody"] !== undefined) {
                setUnderlineBody(bs["underlineBody"]);
              } else if (bs["alignmentTitle"] !== undefined) {
                setAlignmentTitle(bs["alignmentTitle"]);
              } else if (bs["italicTitle"] !== undefined) {
                setItalicTitle(bs["italicTitle"]);
              } else if (bs["boldTitle"] !== undefined) {
                setBoldTitle(bs["boldTitle"]);
              } else if (bs["underlineTitle"] !== undefined) {
                setUnderlineTitle(bs["underlineTitle"]);
              } else if (bs["alignmentHeader"] !== undefined) {
                setAlignmentHeader(bs["alignmentHeader"]);
              } else if (bs["italicHeader"] !== undefined) {
                setItalicHeader(bs["italicHeader"]);
              } else if (bs["boldHeader"] !== undefined) {
                setBoldHeader(bs["boldHeader"]);
              } else if (bs["underlineHeader"] !== undefined) {
                setUnderlineHeader(bs["underlineHeader"]);
              } else if (bs["activeKeyAccordian"] !== undefined) {
                setActiveKeyAccordian(Number(bs["activeKeyAccordian"]));
              } else if (bs["bgColorTitle"] !== undefined) {
                setColorBGSelectedOptionTitle(bs["bgColorTitle"]);
              } else if (bs["bgColorSearchField"] !== undefined) {
                setBGColorSF(bs["bgColorSearchField"]);
              }
            });
          } else {
            setFontColorSelectedOptionTitle("#000000");
            setFontSizeSelectedOptionTitle("medium");
            setFontFamilySelectedOptionTitle("Segoe UI");
            setFontColorSelectedOptionHeader("#000000");
            setFontSizeSelectedOptionHeader("medium");
            setFontFamilySelectedOptionHeader("Segoe UI");
            setFontColorSelectedOptionBody("#000000");
            setFontSizeSelectedOptionBody("medium");
            setFontFamilySelectedOptionBody("Segoe UI");
            setAlignmentBody("center");
            setItalicBody(false);
            setBoldBody(false);
            setUnderlineBody(false);
            setAlignmentTitle("center");
            setItalicTitle(false);
            setBoldTitle(false);
            setUnderlineTitle(false);
            setAlignmentHeader(props.selected === "1" ? "left" : "center");
            setItalicHeader(false);
            setBoldHeader(false);
            setUnderlineHeader(false);
            setActiveKeyAccordian(0);
            setColorBGSelectedOptionTitle("#F7F7F7");
            setBGColorSF("#FFFFFF");
            
            skipValuesRef.current = false;
            setSkipValues(false);
          }
          setInitialLoad(0);
        }
      }
    }
  }, [initialLoadRef.current, props.blockId, props.initialValuesFromAPI, skipValuesRef.current]);

  return (
    <>
      <Form.Control
        className={`${props.isProd ? "mb-1 styleField d-none" : "mb-1 styleField"}`}
        readOnly
        type="datasource"
        value={activeKeyAccordian}
        id="activeKeyAccordian"
      />
      <Accordion onSelect={setActiveKeyAccordianOnClick} activeKey={activeKeyAccordian}>
        <Accordion.Item eventKey={1}>
          <Accordion.Header>Styles</Accordion.Header>
          <Accordion.Body>
            <Row>
              <Col style={{ textAlign: "center", fontWeight: "bold" }}>{props.selected === "5" || props.selected === "3" ? "Button" : "Title"}</Col>
            </Row>
            <Row className="py-2">
              <Col>Background:</Col>
              <Col>
                <Form.Control
                  type="color"
                  className="styleField"
                  onChange={setColorBGSelectedOptionTitleOnClick}
                  value={colorBGSelectedOptionTitle}
                  id="bgColorTitle"
                  title="Choose your background color"
                />
              </Col>
            </Row>
            <Row className="py-2">
              <Col>Font Size: </Col>
              <Col>
                <Form.Select
                  value={fontSizeSelectedOptionTitle}
                  onChange={setFontSizeSelectedOptionSelectedTitle}
                  id="fontSizeInputTitle"
                  type="fieldselect"
                  className="styleField"
                >
                  <option value="xx-small">XXS</option>
                  <option value="x-small">XS</option>
                  <option value="small">Small</option>
                  <option value="medium">Medium (Default)</option>
                  <option value="large">Large</option>
                  <option value="x-large">XL</option>
                  <option value="xx-large">XXL</option>
                  <option value="xxx-large">XXXL</option>
                </Form.Select>
              </Col>
            </Row>
            <Row className="py-2">
              <Col>Font Family: </Col>
              <Col>
                <Form.Select
                  value={fontFamilySelectedOptionTitle}
                  onChange={setFontFamilySelectedOptionSelectedTitle}
                  id="fontFamilyInputTitle"
                  type="fieldselect"
                  className="styleField"
                >
                  <option value="Segoe UI">Segoe UI (Default)</option>
                  <option value="Arial">Arial</option>
                  <option value="Verdana">Verdana</option>
                  <option value="Tahoma">Tahoma</option>
                  <option value="Trebuchet MS">Trebuchet MS</option>
                  <option value="Times New Roman">Times New Roman</option>
                  <option value="Georgia">Georgia</option>
                  <option value="Garamond">Garamond</option>
                  <option value="Courier New">Courier New</option>
                  <option value="Brush Script MT">Brush Script MT</option>
                </Form.Select>
              </Col>
            </Row>
            <Row className="py-2">
              <Col>Font Color:</Col>
              <Col>
                <Form.Control
                  type="color"
                  className="styleField"
                  onChange={setFontColorSelectedOptionSelectedTitle}
                  value={fontColorSelectedOptionTitle}
                  id="fontColorInputTitle"
                  title="Choose your color"
                />
              </Col>
            </Row>
            {props.selected !== "5" && props.selected !== "3" ? (
              <Row className="py-2">
                <Col>Alignment:</Col>
                <Col>
                  <Form.Select value={alignmentTitle} onChange={onChangeAlignmentTitle} id="alignmentTitle" type="fieldselect" className="styleField">
                    <option value="left">Left</option>
                    <option value="center">Center (Default)</option>
                    <option value="right">Right</option>
                  </Form.Select>
                </Col>
              </Row>
            ) : (
              <></>
            )}
            <Row className="py-2">
              <Col>
                <Col>Italic:</Col>
                <Col>
                  <Form.Check checked={italicTitle} onChange={onChangeItalicTitle} className="styleField form-check" type="checkbox" id="italicTitle" />
                </Col>
              </Col>
              <Col>
                <Col>Bold:</Col>
                <Col>
                  <Form.Check checked={boldTitle} onChange={onChangeBoldTitle} className="styleField form-check" type="checkbox" id="boldTitle" />
                </Col>
              </Col>
              <Col>
                <Col>Underline:</Col>
                <Col>
                  <Form.Check
                    checked={underlineTitle}
                    onChange={onChangeUnderlineTitle}
                    className="styleField form-check"
                    type="checkbox"
                    id="underlineTitle"
                  />
                </Col>
              </Col>
            </Row>
            {props.selected !== "3" ? (
              <>
                <hr />
                <Row>
                  <Col style={{ textAlign: "center", fontWeight: "bold" }}>
                    {props.selected === "2" ? "Label" : props.selected === "1" ? "Header" : props.selected === "5" ? "Search Field" : ""}
                  </Col>
                </Row>
                {props.selected === "5" ? (
                  <Row className="py-2">
                    <Col>Background:</Col>
                    <Col>
                      <Form.Control
                        type="color"
                        className="styleField"
                        onChange={setBGColorSFOnClick}
                        value={bgColorSF}
                        id="bgColorSearchField"
                        title="Choose your background color"
                      />
                    </Col>
                  </Row>
                ) : (
                  <></>
                )}
                <Row className="py-2">
                  <Col>Font Size: </Col>
                  <Col>
                    <Form.Select
                      value={fontSizeSelectedOptionHeader}
                      onChange={setFontSizeSelectedOptionSelectedHeader}
                      id="fontSizeInputHeader"
                      type="fieldselect"
                      className="styleField"
                    >
                      <option value="xx-small">XXS</option>
                      <option value="x-small">XS</option>
                      <option value="small">Small</option>
                      <option value="medium">Medium (Default)</option>
                      <option value="large">Large</option>
                      <option value="x-large">XL</option>
                      <option value="xx-large">XXL</option>
                      <option value="xxx-large">XXXL</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col>Font Family: </Col>
                  <Col>
                    <Form.Select
                      value={fontFamilySelectedOptionHeader}
                      onChange={setFontFamilySelectedOptionSelectedHeader}
                      id="fontFamilyInputHeader"
                      type="fieldselect"
                      className="styleField"
                    >
                      <option value="Segoe UI">Segoe UI (Default)</option>
                      <option value="Arial">Arial</option>
                      <option value="Verdana">Verdana</option>
                      <option value="Tahoma">Tahoma</option>
                      <option value="Trebuchet MS">Trebuchet MS</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Garamond">Garamond</option>
                      <option value="Courier New">Courier New</option>
                      <option value="Brush Script MT">Brush Script MT</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col>Font Color: </Col>
                  <Col>
                    <Form.Control
                      type="color"
                      className="styleField"
                      onChange={setFontColorSelectedOptionSelectedHeader}
                      value={fontColorSelectedOptionHeader}
                      id="fontColorInputHeader"
                      title="Choose your color"
                    />
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col>Alignment: </Col>
                  <Col>
                    <Form.Select value={alignmentHeader} onChange={onChangeAlignmentHeader} id="alignmentHeader" type="fieldselect" className="styleField">
                      {props.selected === "1" ? (
                        <>
                          <option value="left">Left (Default)</option>
                          <option value="right">Right</option>
                        </>
                      ) : (
                        <>
                          <option value="left">Left</option>
                          <option value="center">Center (Default)</option>
                          <option value="right">Right</option>
                        </>
                      )}
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col>
                    <Col>Italic:</Col>
                    <Col>
                      <Form.Check checked={italicHeader} onChange={onChangeItalicHeader} className="styleField form-check" type="checkbox" id="italicHeader" />
                    </Col>
                  </Col>
                  <Col>
                    <Col>Bold:</Col>
                    <Col>
                      <Form.Check checked={boldHeader} onChange={onChangeBoldHeader} className="styleField form-check" type="checkbox" id="boldHeader" />
                    </Col>
                  </Col>
                  <Col>
                    <Col>Underline:</Col>
                    <Col>
                      <Form.Check
                        checked={underlineHeader}
                        onChange={onChangeUnderlineHeader}
                        className="styleField form-check"
                        type="checkbox"
                        id="underlineHeader"
                      />
                    </Col>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
            {props.selected !== "5" && props.selected !== "3" ? (
              <>
                <hr />
                <Row>
                  <Col style={{ textAlign: "center", fontWeight: "bold" }}>{props.selected === "2" ? "Value" : props.selected === "1" ? "Table" : ""}</Col>
                </Row>
                <Row className="py-2">
                  <Col>Font Size: </Col>
                  <Col>
                    <Form.Select
                      value={fontSizeSelectedOptionBody}
                      onChange={setFontSizeSelectedOptionSelectedBody}
                      id="fontSizeInputBody"
                      type="fieldselect"
                      className="styleField"
                    >
                      <option value="xx-small">XXS</option>
                      <option value="x-small">XS</option>
                      <option value="small">Small</option>
                      <option value="medium">Medium (Default)</option>
                      <option value="large">Large</option>
                      <option value="x-large">XL</option>
                      <option value="xx-large">XXL</option>
                      <option value="xxx-large">XXXL</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col>Font Family: </Col>
                  <Col>
                    <Form.Select
                      value={fontFamilySelectedOptionBody}
                      onChange={setFontFamilySelectedOptionSelectedBody}
                      id="fontFamilyInputBody"
                      type="fieldselect"
                      className="styleField"
                    >
                      <option value="Segoe UI">Segoe UI (Default)</option>
                      <option value="Arial">Arial</option>
                      <option value="Verdana">Verdana</option>
                      <option value="Tahoma">Tahoma</option>
                      <option value="Trebuchet MS">Trebuchet MS</option>
                      <option value="Times New Roman">Times New Roman</option>
                      <option value="Georgia">Georgia</option>
                      <option value="Garamond">Garamond</option>
                      <option value="Courier New">Courier New</option>
                      <option value="Brush Script MT">Brush Script MT</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col>Font Color:</Col>
                  <Col>
                    <Form.Control
                      type="color"
                      className="styleField"
                      onChange={setFontColorSelectedOptionSelectedBody}
                      value={fontColorSelectedOptionBody}
                      id="fontColorInputBody"
                      title="Choose your color"
                    />
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col>Alignment:</Col>
                  <Col>
                    <Form.Select value={alignmentBody} onChange={onChangeAlignmentBody} id="alignmentBody" type="fieldselect" className="styleField">
                      <option value="center">Center (Default)</option>
                      <option value="left">Left</option>
                      <option value="right">Right</option>
                    </Form.Select>
                  </Col>
                </Row>
                <Row className="py-2">
                  <Col>
                    <Col>Italic:</Col>
                    <Col>
                      <Form.Check checked={italicBody} onChange={onChangeItalicBody} className="styleField form-check" type="checkbox" id="italicBody" />
                    </Col>
                  </Col>
                  <Col>
                    <Col>Bold:</Col>
                    <Col>
                      <Form.Check checked={boldBody} onChange={onChangeBoldBody} className="styleField form-check" type="checkbox" id="boldBody" />
                    </Col>
                  </Col>
                  <Col>
                    <Col>Underline:</Col>
                    <Col>
                      <Form.Check
                        checked={underlineBody}
                        onChange={onChangeUnderlineBody}
                        className="styleField form-check"
                        type="checkbox"
                        id="underlineBody"
                      />
                    </Col>
                  </Col>
                </Row>
              </>
            ) : (
              <></>
            )}
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
};
