import { matchRoutes, useLocation } from "react-router-dom";

const useCurrentPage = (availablePages) => {
    if (!availablePages || availablePages.length === 0) {
        return {};
    }

    const location = useLocation();
    const matches = matchRoutes(availablePages, location);

    return matches?.length > 0 ? matches[0].route : {};
};

export default useCurrentPage;
