import React, { useState, useRef, useMemo, useEffect } from "react";

import { AgGridReact } from "ag-grid-react";

export const TableBlock = (props) => {
  const gridRef = useRef();
  const [initialLoad, setInitialLoad] = useState(true);
  const initialLoadRef = useRef(true)
  initialLoadRef.current = initialLoad

  function LinkComponent(props) {
    let content = "";
    let url;
    try {
      url = new URL(props.value);
    } catch (ex) {
      console.warn("Invalid URL for table", props.value, ex);
      url = props.value;
    }
    content = (
      <a href={url} rel="noopener noreferrer" target="_blank">
        {props.data.hlTitle === undefined ? props.value : props.data.hlTitle}
      </a>
    );
    return content;
  }

  if (props.mapping.length === 0) {
    console.warn("Missing Mappings For TableBlock", props.id);
  }

  let tblColumns = [];
  props.mapping.forEach((col) => {
    let traditionalStylesTitle = "";
    let traditionalStylesHeader = "";
    let headerAlign = "";
    let traditionalStylesBody = "";
    if (props.blockStyles !== undefined) {
      if (props.blockStyles.length !== 0) {
        props.blockStyles.forEach((bs) => {
          if (bs["fontColorInputTitle"] !== undefined) {
            traditionalStylesTitle = traditionalStylesTitle + "color:" + bs["fontColorInputTitle"] + ";";
          } else if (bs["fontSizeInputTitle"] !== undefined) {
            traditionalStylesTitle = traditionalStylesTitle + "font-size:" + bs["fontSizeInputTitle"] + ";";
          } else if (bs["fontFamilyInputTitle"] !== undefined) {
            traditionalStylesTitle = traditionalStylesTitle + "font-family:" + bs["fontFamilyInputTitle"] + ";";
          } else if (bs["alignmentTitle"] !== undefined) {
            traditionalStylesTitle = traditionalStylesTitle + "text-align:" + bs["alignmentTitle"] + ";";
          } else if (bs["italicTitle"] !== undefined) {
            traditionalStylesTitle = traditionalStylesTitle + "font-style:" + (bs["italicTitle"] === false ? "normal" : "italic") + ";";
          } else if (bs["boldTitle"] !== undefined) {
            traditionalStylesTitle = traditionalStylesTitle + "font-weight:" + (bs["boldTitle"] === false ? "normal" : "bold !important") + ";";
          } else if (bs["bgColorTitle"] !== undefined) {
            traditionalStylesTitle = traditionalStylesTitle + "background-color:" + bs["bgColorTitle"] + ";";
          } else if (bs["underlineTitle"] !== undefined) {
            traditionalStylesTitle = traditionalStylesTitle + "text-decoration:" + (bs["underlineTitle"] === false ? "none" : "underline") + ";";
          } else if (bs["fontColorInputHeader"] !== undefined) {
            traditionalStylesHeader = traditionalStylesHeader + "color:" + bs["fontColorInputHeader"] + ";";
          } else if (bs["fontSizeInputHeader"] !== undefined) {
            traditionalStylesHeader = traditionalStylesHeader + "font-size:" + bs["fontSizeInputHeader"] + ";";
          } else if (bs["fontFamilyInputHeader"] !== undefined) {
            traditionalStylesHeader = traditionalStylesHeader + "font-family:" + bs["fontFamilyInputHeader"] + ";";
          } else if (bs["alignmentHeader"] !== undefined) {
            if (bs["alignmentHeader"] === "right") {
              headerAlign = "ag-right-aligned-header";
            }
          } else if (bs["italicHeader"] !== undefined) {
            traditionalStylesHeader = traditionalStylesHeader + "font-style:" + (bs["italicHeader"] === false ? "normal" : "italic") + ";";
          } else if (bs["boldHeader"] !== undefined) {
            traditionalStylesHeader = traditionalStylesHeader + "font-weight:" + (bs["boldHeader"] === false ? "normal" : "bold !important") + ";";
          } else if (bs["underlineHeader"] !== undefined) {
            traditionalStylesHeader = traditionalStylesHeader + "text-decoration:" + (bs["underlineHeader"] === false ? "none" : "underline") + ";";
          } else if (bs["fontColorInputBody"] !== undefined) {
            traditionalStylesBody = traditionalStylesBody + "color:" + bs["fontColorInputBody"] + ";";
          } else if (bs["fontSizeInputBody"] !== undefined) {
            traditionalStylesBody = traditionalStylesBody + "font-size:" + bs["fontSizeInputBody"] + ";";
          } else if (bs["fontFamilyInputBody"] !== undefined) {
            traditionalStylesBody = traditionalStylesBody + "font-family:" + bs["fontFamilyInputBody"] + ";";
          } else if (bs["alignmentBody"] !== undefined) {
            traditionalStylesBody = traditionalStylesBody + "text-align:" + bs["alignmentBody"] + ";";
          } else if (bs["italicBody"] !== undefined) {
            traditionalStylesBody = traditionalStylesBody + "font-style:" + (bs["italicBody"] === false ? "normal" : "italic") + ";";
          } else if (bs["boldBody"] !== undefined) {
            traditionalStylesBody = traditionalStylesBody + "font-weight:" + (bs["boldBody"] === false ? "normal" : "bold !important") + ";";
          } else if (bs["underlineBody"] !== undefined) {
            traditionalStylesBody = traditionalStylesBody + "text-decoration:" + (bs["underlineBody"] === false ? "none" : "underline") + ";";
          }
        });
      }
    }

    if (initialLoadRef.current) {
      const styleTitle = document.createElement("style");
      let styleHtml = String((".cssTitle_" + props.id + " {" + JSON.stringify(traditionalStylesTitle) + "}").replaceAll('"', ""));
      styleTitle.innerHTML = styleHtml;
      document.head.appendChild(styleTitle);

      const styleHeader = document.createElement("style");
      styleHtml = String((".cssHeader_" + props.id + " {" + JSON.stringify(traditionalStylesHeader) + "}").replaceAll('"', ""));
      styleHeader.innerHTML = styleHtml;
      document.head.appendChild(styleHeader);

      const styleBody = document.createElement("style");
      styleHtml = String((".cssBody_" + props.id + " {" + JSON.stringify(traditionalStylesBody) + "}").replaceAll('"', ""));
      styleBody.innerHTML = styleHtml;
      document.head.appendChild(styleBody);

      initialLoadRef.current = false
      setInitialLoad(false)
    }

    if (col[1].isUrl) {
      tblColumns.push({
        cellClass: "cssBody_" + props.id,
        headerClass: ["cssHeader_" + props.id, headerAlign],
        field: col[1].columnName,
        flex: 1,
        minWidth: 200,
        wrapText: true,
        autoHeight: true,
        cellRenderer: LinkComponent,
      });
    } else {
      tblColumns.push({
        cellClass: "cssBody_" + props.id,
        headerClass: ["cssHeader_" + props.id, headerAlign],
        field: col[1].columnName,
        flex: 1,
        minWidth: 200,
        wrapText: true,
        autoHeight: true,
      });
    }
  });

  let tblData = [];
  props.mapping.forEach((col) => {
    tblData.push({ [col[1].columnName]: col[1].fieldmappath });
    if (col[1].isUrl) {
      tblData.push({ ["hlTitle"]: col[1].hlTitle });
    }
  });

  let blockData = {};
  const blockArr = [];

  if (props.content !== "" && props.content !== undefined) {
    const arrObj = Object.entries(props.content);
    const blockContent = arrObj.find((c) => c[0] === String(props.dataSource));
    if (blockContent !== undefined) {
      blockContent[1].forEach((bc) => {
        tblData.forEach((td) => {
          const curValue = Object.values(td)[0];
          const curKey = Object.keys(td)[0];
          blockData[curKey] = bc[curValue];
        });
        blockArr.push(blockData);
        blockData = {};
      });
    } else {
      console.warn("Invalid mapping for", props.blockName);
    }
  }

  const [columnDefs, setColumnDefs] = useState([tblColumns]);

  if (JSON.stringify(columnDefs) !== JSON.stringify(tblColumns)) {
    setColumnDefs(tblColumns);
  }

  const [rowData, setRowData] = useState([blockArr]);

  if (JSON.stringify(rowData) !== JSON.stringify(blockArr)) {
    setRowData(blockArr);
  }

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
      sortable: true,
      wrapHeaderText: true,
      autoHeaderHeight: true,
      flex: 1,
      minWidth: 100,
    }),
    []
  );

  useEffect(() => {
    props.onLayoutChangeBlock(props.allLayouts);
  });

  return (
    <div id={props.keyId} className="card mt-2">
      <div className={"card-header cssTitle_" + props.id}>{props.blockName}</div>
      <div style={{ height: "100%", width: "100%" }}>
        <div style={{ display: "flex", flexDirection: "row", height: "100%" }}>
          <div style={{ flexGrow: "1" }}>
            <div style={{ height: "100%", width: "100%" }}>
              <AgGridReact
                ref={gridRef}
                className="ag-theme-alpine"
                animateRows="true"
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                rowData={rowData}
                rowSelection="multiple"
                suppressRowClickSelection="true"
                domLayout="autoHeight"
                style={{ width: "100%" }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
