import { authService } from "./index.js";
import axios from "axios";
import { getCurrentPage } from "./index";

const STANDARD_SCOPES = [
    "openid",
    "profile",
    "https://lsonsightplus.onmicrosoft.com/mtrest/generic_oauth2_access",
];

const getToken = async (scopes) => {
    const account = authService.getUserAccount();
    let accessToken = "";

    if (!account.isAuthenticated()) {
        throw Error(
            "No active account! Verify a user has been signed in and setActiveAccount has been called."
        );
    }

    try {
        // First try using cached or silently-refreshed token
        accessToken = await authService.acquireTokenSilent(scopes);
    } catch (error) {
        if (error instanceof InteractionRequiredAuthError) {
            // Silent token refresh not allowed; show popup to force user re-auth
            accessToken = await authService.acquireTokenPopup(scopes);
        }
    }

    return accessToken;
};

const makeHeaders = async (scopes, extraHeaders) => {
    const accessToken = await getToken(scopes || STANDARD_SCOPES);
    return {
        Authorization: `Bearer ${accessToken}`,
        "X-OnsightPlus-WebAppPage": getCurrentPage().name,
        ...extraHeaders,
    };
};

const get = async (path, scopes, extraHeaders) => {
    let options = {
        headers: await makeHeaders(scopes, extraHeaders),
    };

    return await axios.get(
        `${process.env.REACT_APP_ONSIGHT_PLUS_EXT}/v1/${path}`,
        options
    );
};

const post = async (path, body, scopes, extraHeaders) => {
    let options = {
        headers: await makeHeaders(scopes, extraHeaders),
    };

    return await axios.post(
        `${process.env.REACT_APP_ONSIGHT_PLUS_EXT}/v1/${path}`,
        body,
        options
    );
};

const put = async (path, body, scopes, extraHeaders) => {
    let options = {
        headers: await makeHeaders(scopes, extraHeaders),
    };

    return await axios.put(
        `${process.env.REACT_APP_ONSIGHT_PLUS_EXT}/v1/${path}`,
        body,
        options
    );
};

export class UserWebApps {
    /**
     * Gets all relevant app configs for the current user.
     * This should be called immediately after the user has been authenticated
     * in order to determine which configurations (layouts) are applicable to that user.
     * @returns
     */
    static async getUserConfigs(pageId) {
        const response = await get(
            `/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/user-configs`
        );
        return response.data;
    }

    static async getUserPages() {
        const response = await get(
            `/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/user-pages`
        );
        return response.data;
    }
}

export class CallCenter {
    static getRequests(extNameId) {
        return get(`call-center/${extNameId}/requests`);
    }

    static makeRequest(extNameId, request) {
        return post(`call-center/${extNameId}/requests`, request);
    }

    static initiateCall(extNameId, requestId) {
        return post(`call-center/${extNameId}/requests/${requestId}/calls`, {});
    }
};