import React, { useState } from "react";
import { useEffect } from "react";
import { Row, Col, Card, Form, Container as BSContainer } from "react-bootstrap";
import { Button } from "react-bootstrap";
import { WebAppConfigs } from "../../onsight-plus";

export const FileUpload = (props) => {
  const [fileName, setFileName] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [initialLoad, setInitialLoad] = useState(true);
  const [assets, setAssets] = useState([]);
  const [id, setId] = useState(0);
  const [updateAssets, setUpdateAssets] = useState(false);

  const getFile = async (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const onChange = (event) => {
    setFileName(event.target.value);
  };

  const uploadFile = async () => {
    setUpdateAssets(true);
    const formData = new FormData();
    formData.append("asset", selectedFile);
    const result = await WebAppConfigs.postAsset(formData, "images");
    let location = decodeURI(result);
    if (location.includes("%26")) {
      location = location.replace("%26", "&")
    }
    const newId = id + 1;
    setId(newId);
    setAssets((assets) => [...assets, { name: fileName, location: location, id: newId }]);
  };

  const removeFile = async (id) => {
    setUpdateAssets(true);
    let asset = assets.find((a) => a.id === id);
    let relPathName = asset.location.split("?");
    let relPath = relPathName[0].replace(`https://cogswellsprocketssto.blob.core.windows.net/assets/${process.env.REACT_APP_B2C_CLIENT_ID}/`, "");
    const resp = await WebAppConfigs.removeAsset(relPath);
    let newAssets = [];
    assets.forEach((a) => {
      if (a.id !== id) {
        newAssets.push(a);
      }
    });
    setAssets([...newAssets]);
  };

  const clickChooseFile = () => {
    document.querySelector("#fileName").value = "";
    setFileName("");
  };

  if (initialLoad) {
    let values = props.initialValuesFromAPI.filter((iv) => iv.id === "fileUploadId");
    if (values.length !== 0) {
      let inputs = values[0].inputs;
      let mappedInputs = inputs.map((i) => {
        setId(Number(i[1].assetId));
        return {
          ["id"]: i[1].assetId,
          ["name"]: i[1].assetName,
          ["location"]: i[1].assetUrl,
        };
      });
      setAssets(mappedInputs);
      setInitialLoad(false);
    }
  }

  useEffect(() => {
    if (updateAssets) {
      setUpdateAssets(false);
      props.hitSaveBtn();
    }
  }, [assets]);

  return (
    <Card className="px-0 pl-1 pb-1 mt-1 mb-2">
      <BSContainer>
        <Card className="my-1 childContent fileUploadTable" id="fileUploadId" key="fileUploadKey">
          <BSContainer fluid={true}>
            <Form.Group className="mt-3 mb-2">
              <Form.Label>Media Uploader</Form.Label>
              <Form.Control
                className={`${props.isProd ? "mb-1 blockId d-none" : "mb-1 blockId"}`}
                readOnly
                type="fieldname"
                value="fileUploadId"
                placeholder="Enter Block ID"
              />
              <Form.Control
                className={`${props.isProd ? "mb-1 blockKey d-none" : "mb-1 blockKey"}`}
                readOnly
                type="fieldname"
                value="fileUploadKey"
                placeholder="Enter Block Key"
              />
              <Form.Control
                type="file"
                id="fileUploadFile"
                onClick={clickChooseFile}
                onChange={getFile}
                className="valueField"
                accept=".pdf, image/jpeg, image/png, image/jpg"
              />
              <Form.Control type="fieldname" id="fileName" onChange={onChange} defaultValue={fileName} placeholder="Enter Name" className="valueField" />
              <Button variant="primary" onClick={uploadFile}>
                Upload
              </Button>
            </Form.Group>
            <Form.Group className="mb-3 mappingInput">
              {assets.map((asset) => {
                return (
                  <div className="mappingFields" id={`${asset.id}_mappingInput`} key={asset.id}>
                    <div key={asset.id} id="fuContain">
                      <BSContainer>
                        <Row className="mb-2 w-100">
                          <Col className="col-auto">
                            <Form.Control type="fieldname" id="assetName" defaultValue={asset.name} readOnly className="valueField" />
                          </Col>
                          <Col className="p-0 col-auto" style={{ textAlign: "end" }}>
                            <Button type="button" onClick={() => removeFile(asset.id)} className="btn btn-secondary">
                              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-trash" viewBox="0 0 16 16">
                                <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5zm3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0V6z" />
                                <path
                                  fillRule="evenodd"
                                  d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1v1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4H4.118zM2.5 3V2h11v1h-11z"
                                />
                              </svg>
                            </Button>
                          </Col>
                        </Row>
                        <Row className={`${props.isProd ? "mb-2 w-100 d-none" : "mb-2 w-100"}`}>
                          <Col className="col-auto">
                            <Form.Control type="pathName" id="assetUrl" defaultValue={asset.location} readOnly className="valueField" />
                          </Col>
                        </Row>
                        <Row className={`${props.isProd ? "mb-2 w-100 d-none" : "mb-2 w-100"}`}>
                          <Col className="col-auto">
                            <Form.Control type="pathName" id="assetId" defaultValue={asset.id} readOnly className="valueField" />
                          </Col>
                        </Row>
                      </BSContainer>
                    </div>
                  </div>
                );
              })}
            </Form.Group>
          </BSContainer>
        </Card>
      </BSContainer>
    </Card>
  );
};
