import { authService } from "./index.js";
import axios from "axios";

const STANDARD_SCOPES = ["openid", "profile", "https://lsonsightplus.onmicrosoft.com/mtrest/generic_oauth2_access"];

const getToken = async (scopes) => {
  const account = authService.getUserAccount();
  let accessToken = "";
  
  if (!account.isAuthenticated()) {
    throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
  }

  try {
    // First try using cached or silently-refreshed token
    accessToken = await authService.acquireTokenSilent(scopes);
  } catch (error) {
    if (error instanceof InteractionRequiredAuthError) {
      // Silent token refresh not allowed; show popup to force user re-auth
      accessToken = await authService.acquireTokenPopup(scopes);
    }
  }

  return accessToken;
};

const makeHeaders = async (scopes, extraHeaders) => {
  const accessToken = await getToken(scopes || STANDARD_SCOPES);
  return {
    Authorization: `Bearer ${accessToken}`,
    ...extraHeaders,
  };
};

const get = async (path, scopes, extraHeaders, isAPI = false) => {
  let options = {
    headers: await makeHeaders(scopes, extraHeaders),
  };

  let url = `${process.env.REACT_APP_ONSIGHT_PLUS_API}/v1${path}`;
  if (isAPI) {
    url = path;
  }

  return await axios.get(url, options);
};

const post = async (path, body, scopes, extraHeaders) => {
  let options = {
    headers: await makeHeaders(scopes, extraHeaders),
  };

  return await axios.post(`${process.env.REACT_APP_ONSIGHT_PLUS_API}/v1${path}`, body, options);
};

const put = async (path, body, scopes, extraHeaders) => {
  let options = {
    headers: await makeHeaders(scopes, extraHeaders),
  };

  return await axios.put(`${process.env.REACT_APP_ONSIGHT_PLUS_API}/v1${path}`, body, options);
};

const del = async (path, scopes, extraHeaders) => {
  let options = {
    headers: await makeHeaders(scopes, extraHeaders),
  };
  return await axios.delete(`${process.env.REACT_APP_ONSIGHT_PLUS_API}/v1${path}`, options);
};

export class WebAppConfigs {
  /**
   * Gets all relevant app configs for the current user.
   * This should be called immediately after the user has been authenticated
   * in order to determine which configurations (layouts) are applicable to that user.
   * @returns
   */
  static async getAll(pageId) {
    const response = await get(`/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/user-configs`);
    return response.data;
  }

  /**
   * Gets data via input if configured to use API.
   * This should be called immediately when the App renders.
   * @returns
   */
  static async getData(endpoint) {
    const response = await get(endpoint, undefined, undefined, true);
    return response.data;
  }

  /**
   * Gets the app config blob with the given config ID and blob type.
   * For use by Admin role in editing an app configuration/layout.
   * @param {*} confId
   * @param {*} blobType
   * @returns
   */
  static async get(pageId, confId, blobType) {
    const response = await get(`/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/configs/${confId}/${blobType}`);
    return response.data;
  }

  /**
   * Updates the app config (e.g., layout) with the new value.
   * For use by Admin role in editing an app configuration/layout.
   * @param {*} updatedConfig
   * @returns
   */
  static async update(pageId, updatedConfig) {
    const response = await put(`/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/configs/${updatedConfig.definitionId}`, updatedConfig);
    return response.data;
  }

  /**
  /**
   * Updates the UILayouts with the new value.
   * For use by Admin role in editing an app layout.
   * @param {*} relationalId
   * @param {*} updatedConfig
   * @returns
   */
  static async updateUILayout(pageId, relationalId, updatedConfig) {
    const response = await put(`/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/configs/${relationalId}/UILayouts`, updatedConfig);
    return response.data;
  }

  /**
   * Updates the UIConfigs with the new value.
   * For use by Admin role in editing an app configurations.
   * @param {*} relationalId
   * @param {*} updatedConfig
   * @returns
   */
  static async updateUIConfig(pageId, relationalId, updatedConfig) {
    const response = await put(`/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/pages/${pageId}/configs/${relationalId}/UIConfigs`, updatedConfig);
    return response.data;
  }

  static async getUserPages() {
    const response = await get(`/web-apps/${process.env.REACT_APP_B2C_CLIENT_ID}/user-pages`);
    return response.data;
  }

  /**
   * Retrieves the URL to that asset (stored on Azure Blob Storage).
   * @param {*} relPathName
   * @returns
   */
  static async getAsset(relPathName) {
    const response = await get(`/assets/${process.env.REACT_APP_B2C_CLIENT_ID}/${relPathName}`);
    return response.data;
  }

  /**
   * Takes multipart/form-data (file attachment) and stores it under the given relative path..
   * @param {*} attachment
   * @param {*} relPath
   * @returns
   */
  static async postAsset(attachment, relPath) {
    const response = await post(`/assets/${process.env.REACT_APP_B2C_CLIENT_ID}/${relPath}`, attachment);
    return response.data;
  }

  /**
   * Remove the asset specified in the path (stored on Azure Blob Storage).
   * @param {*} relPathName
   * @returns
   */
  static async removeAsset(relPathName) {
    const response = await del(`/assets/${process.env.REACT_APP_B2C_CLIENT_ID}/${relPathName}`);
    return response.data;
  }
}
